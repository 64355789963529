.Event {
    width: 25vw;
    height: 25vw;
    margin: 2vw 2vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    box-shadow: rgba(0,0,0,0.25) 0px 2px 6px 2px;
}
.Event .InfoWrapper {
   
    position: absolute;;
    bottom: 0;
    width: 100%;
  
}
.Event .InfoWrapper .Day{
    width: 5vw;
    height: 5vw;
    background: #ddd;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.Event .InfoWrapper .Day .DayNum,.Event .InfoWrapper .Day .DayText {
    font-family: 'MyFont';
    flex:0.5;
    font-size: 1.2em;
}
.Event .InfoWrapper .Title {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 10px 20px;
    background: #000;
    color: #ffffff
}