@font-face {
    font-family: 'MyFont';
    src: url('/static/fonts/MyFontRegular.ttf');
  }

.ShopList {
    background:white;
    background-image: url('/static/bg_pattern.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    overflow-y: scroll;
}
.ListContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around
}
.StoreTile {
    position: relative;
    box-sizing: border-box;
    padding: 30px 0px 10px;
    margin: 8vh 4vw;
    width: 25vw;
    box-shadow: rgba(0,0,0,0.25) 0px 2px 6px 2px;
    align-self: auto;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.TileImage {
    margin-left:10px;
    margin-right: 10px;
    max-width: 80%;
    width: 150px;
    align-self: center;
    flex: 1;
}
.Category {
    position: absolute;
    width: 100%;
    height:5px;
    top:0;
}
.Title {
    font-family: 'MyFont';
    margin-top: 20px;
    font-size: 1.25em;
}
.FloorWrapper {
    width: 80%;
    margin-left: 10%;
    text-align: center;
    transform: skewX(-10deg);
    background-color: black

}
.FloorInner {
    font-family: 'MyFont';
    margin-top: 20px;
    transform: skewX(10deg);
    color: white;
    line-height: 2em;

}
.SearchContainer {
    width: 100%;
}
.Search {
    margin-top: 20px;
    border:4px solid black;
    transform: skewX(-10deg);
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    
}
.SearchInput {
    border: none;
    font-family: 'MyFont';
    font-size:1.25em;
    transform: skewX(10deg);
    width: 100%;
    outline: none
}
.SearchInput:active,.SearchInput:hover,.SearchInput:focus{
    border: none;
    outline: none
}
.Categories{
    display: flex;
    margin-top: 20px;
}

.ShopContainer {
    z-index: 100;
    background: white;
    position: absolute;
    width: 70vw;
    height: 80vh;
    left: 15%;
    right: 15%;
    top:100%;
    transition: 0.5s;
    box-shadow: rgba(0,0,0,0.25) 0px 2px 6px 2px;
    display: none;
    
  }
  .ShopContainer.show {
    top: 10%;
    transition: 0.5s;
    display: block;
  }
  .ShopContainer .InnerContent {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row
  }
  .InnerContent .Sidebar{
      flex: 0.3;
      

  }
  .Sidebar .TitleWrapper {
      width: 100%;
      background-image: url('/static/sidebar_logo_bg.png');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      height:30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
  }
  .TitleWrapper .Text {
      flex: 0.5;
      font-family: 'MyFont';
      font-size:1.25rem;
  }
  .InnerContent .Content {
      background-image: url('/static/bg_pattern.jpg');
      background-size: cover;
      flex: 0.7;
      flex-direction: column;
  }
  .ImageWrapper img {
      max-width: 50%;
      max-height: 45vh;
  }
  .Content .ImageWrapper {
      flex: 1;
  }
  .Content .TextWrapper {
    flex: 1;
}
.TextWrapper .Description {
    text-align: justify;
    margin-left: 10px; margin-right: 10px;
}
.Info * {
    font-family: 'MyFont';
}
.Info {
    margin-bottom: 40px;
}
.InfoLine, .InfoElement {
    margin-top: 10px;
    padding-left:20px;
    padding-right: 10px;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
}
.ContactHeader {
    font-size: 1.2em;
}
.InfoLine .LocationText, .InfoElement .InfoLabel {
    font-family:'MyFont';
    font-size:1.1rem;
    text-align: left;
    flex:0.5;
    color: #888;
}
.InfoLine .LocationNumber, .InfoElement .InfoValue {
    font-family:'MyFont';
    font-size:1.1rem;
    text-align: right;
    flex:0.5;

}
.InfoElement .InfoValue {
    font-size: 0.9em;
}
.InfoValue.Small {
    font-size:0.7em;
}
.Close {
        background: rgba(255,255,255,0.25);
        transform: skewX(-10px);
        z-index: 2;
        position: absolute;
        right: 2vh;
        top: 2vh;
    
}

.Sidebar .FloorButton {
    margin: 20px auto;
}