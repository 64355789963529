.ButtonWrapper {
    flex:1;
    justify-content: center;
    align-items: center;
}
.FloorButton {
    background: #000;
    color: #fff;
    transform: skewX(-10deg);
    padding-top: 5px;
    padding-bottom: 5px;
    width: 80%;
    display: flex;
    justify-content: center;

}
.FloorButton.Navigate{
    padding-top: 15px;
    padding-bottom: 15px;
}

.FloorButton .Text{
    transform: skewX(10deg);
    color: #fff;
    font-family: 'MyFont';
    font-size:1.2em
}

.Text.Large{
    font-size:1.5em
}
.FloorButton.active {
    background: #fff;
    color: #000;
    border: 5px solid black;
}
.FloorButton.active .Text{
    transform: skewX(10deg);
    color: #000000;
    font-family: 'MyFont';
    font-size:1.2em
}
.ButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    height: 100%;
}

.SearchContainer {
    flex: 3;
    padding-top:20px;
}
.MapNavigation {
    background-image: url('/static/bg_pattern.jpg');
    background-size:cover;

    width: 100%;
    height: 100%;
    flex-direction: row;
    display: flex;
}
.FloorContainer {
    flex: 4;
}

.NaviPath {
    width: 100%,;
    height: 100%;
     position: absolute;
      bottom:0;
      left:5%;
}
.InnerFloor{
    
        width: 100%;
        flex:1;
        height:100%;
        top: 5%;
}
.react-autosuggest__container {
    display: flex;
}
.react-autosuggest__container .MapSearch {
    border: 4px solid black;
    
    font-family: 'MyFont';
    font-size:1em;
    transform: skewX(-10deg);
    width: 80%;
    outline: none
}
.react-autosuggest__container .react-autosuggest__suggestions-container{
    width:280px;
    display: flex;
    flex:1;
    position: absolute;
}
.react-autosuggest__container .react-autosuggest__suggestions-container ul{
    background: white;
    z-index: 999;
    width:100%;
    list-style: none;
    margin-top:38px;
    padding-left:0px;
    
    
}
.react-autosuggest__container .react-autosuggest__suggestions-container ul li{
    border:2px solid black;
    font-family: 'MyFont';
    font-size: 1.1em;
    
}
.Keyboard {
    position: absolute;
    bottom:0;
    z-index: 9999;
    width: 60vw;
    margin-left:50%;
    left:-30vw;
    height:25vh;
    background:#ddd;
}
.hg-button {
    height: 60px!important;
}