.Back {
    font-family: 'MyFont';
    background: rgba(255,255,255,1);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    transform: skewX(-10px);
    z-index: 2;
    position: absolute;
    display: flex;
    left: 2vh;
    top: 2vh;
    padding-right: 12px;
    align-items: center;
    justify-content: start;
}
.Back span {
    font-size: 2.5vh;
    color: #000;
}