.Iframe {
    background:white;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
}
.frame {padding: 0;
    margin: 0;
    border: none;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
}