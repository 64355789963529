@font-face {
    font-family: 'MyFont';
    src: url('/static/fonts/MyFontRegular.ttf');
  }

.Contact {
    background:white;
    background-image: url('/static/bg_pattern.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    overflow-y: scroll;
}
.Contact .ListContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: start;
}
.Contact .ListContainer * {
    text-align: left;
}
.Contact .ListContainer div {
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;

}
.Contact .ListContainer div h2 {
    font-size: 3vh;
    font-family: 'MyFont';
    color: #000;
    padding: 0;
    margin-bottom: 20px;
}
.Contact .ListContainer div h3 {
    font-size: 2h;
    font-family: 'MyFont';
    color: #000;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 10px;
}
.Contact .ListContainer div p {
    font-size: 2vh;
    margin-top: 8px;
    margin-bottom: 8px;
}