@font-face {
  font-family: 'MyFont';
  src: url('/static/fonts/MyFontRegular.ttf');
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}
.App {
  z-index: 1;
  text-align: center;
  background-image: url('/static/bg_gold.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  overflow: hidden;
}

.Row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
}
.SmallRow {
  display: flex;
  flex: 0.5;
  flex-direction: row;
  justify-content: space-around;
}
.IconWrapper {
  display: flex;
  flex: 1;
  width: 33%;
  align-items: center;
  justify-content: center;
}

.MainInfoWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.MainInfoWrapper .InnerInfo {
  flex: 0.66;
  transform: skewX(-10deg);
  align-self: center;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #000;
}
.MainInfoWrapper .InnerInfo p {
  transform: skewX(10deg);
  font-size: 1.75rem;
  padding: 10px 20px;

  color: #fff;
}
.InnerIcon {
  transform: skewX(-10deg);
  padding: 10px 0;
  display: flex;
  flex: 0.66;
  background-color: #000;
  align-self: center;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.InnerIcon > * {
  transform: skewX(10deg);
  display: flex;
}
.InnerIcon svg,
.InnerIcon .IconImage {
  flex: 0.3;
}
.IconImage {
  text-align: center;
  display: block;
}
.InnerIcon p {
  flex: 1;
  display: block;
  font-family: 'MyFont';
  font-size: 1.25rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
  text-align: center;
}

.ModuleContainer {
  z-index: 2;
  background: white;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0;
  transition: 0.5s;
  display: none;
}
.ModuleContainer.show {
  left: 0%;
  transition: 0.5s;
  display: block;
}
.IconImage img {
  height: 58px;
}
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: rgba(0, 0, 0)
    radial-gradient(circle, transparent 1%, rgba(0, 0, 0) 1%) center/15000%;
}
.ripple:active {
  background-color: rgba(100, 100, 100);
  background-size: 100%;
  transition: background 0s;
}
.FlagWrapper {
  width: 50%;

  padding: 20px;
}
.plFlag {
  display: inline-block;
  margin: 20px 0px;
  height: 50px;
  width: 120px;
  background-image: url('/static/flag_pl.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.enFlag {
  display: inline-block;
  height: 50px;
  width: 120px;
  margin: 20px 0px;
  background-image: url('/static/flag_uk.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-size: contain;
}
.saver {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('https://zlotetarasy.app/assets/public/wayfinder');
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
}
.saver-inner {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  background-image: url('/static/cover2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  -webkit-animation: animate-saver 20s infinite; /* Safari 4+ */
  -moz-animation: animate-saver 20s infinite; /* Fx 5+ */
  -o-animation: animate-saver 20s infinite; /* Opera 12+ */
  animation: animate-saver 20s infinite; /* IE 10+, Fx 29+ */
}
@-webkit-keyframes animate-saver {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes animate-saver {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes animate-saver {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes animate-saver {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
