@font-face {
    font-family: 'MyFont';
    src: url('/static/fonts/MyFontRegular.ttf');
  }

.OfferList {
    background:white;
    background-image: url('/static/bg_pattern.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    overflow-y: scroll;
}
.OfferList .ListContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around
}
.OfferContainer {
    z-index: 100;
    background: white;
    position: absolute;
    width: 70vw;
    height: 80vh;
    left: 15%;
    right: 15%;
    top:100%;
    transition: 0.5s;
    box-shadow: rgba(0,0,0,0.25) 0px 2px 6px 2px;
    overflow-y:scroll;

    padding-top: 20px;
    padding-bottom: 20px;
    
  }
  .OfferContainer.show {
    top: 10%;
    transition: 0.5s;
  }
  .TextWrapper .Description {
    padding-right: 15vh;
    padding-left: 10vh;
  }